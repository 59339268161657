
export const lan = {

    'en':[
        'Home',
        'About Us',
        'Our Team',
        'What We Do',
        'How We Do',
        'Contact Us',
        'Blogs',
        {
            'sub_menu':[
                'Web Development',
                'Mobile Development',
                'Social Media Marketing',
                'Search Engine Optimization',
                'Japanese Classes',
                'German Classes',
                'Coding Classes',
            ],
            'sub_menu_link':[
                '/webdevelopment/',
                '/mobiledevelopment/',
                '/smo/',
                '/seo/',
                '/japaneseclasses/',
                '/germanclasses/',
                '/courses/'
            ]
        }
    ],

}