import * as React from "react";
import "../../../gatsby-browser";
import { Helmet } from "react-helmet";
import Loading from "../../static/img/load/load.gif";
import $ from "jquery";
import { Link } from "gatsby";
import axios from "axios";
import { url } from "../../url/url";
import { lan } from "../../lan/Nav";
import { EN, JA, IN,Logo } from "../../lan/image";


// const GTM_ID = 'G-986CMWQX2S';

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      isOpen: "false",
      lan: "en",
      show: "",
      pathname: "",
    };

    this.langs = "";
  }

  openDropDown = () => {
    $(".dropdown-content").toggleClass("show");
  };

  openNav = () => {
    $("#mySidenav").css("width", "290px");
    this.setState({
      isOpen: "true",
    });
  };

  closeNav = () => {
    $("#mySidenav").css("width", "0px");
    this.setState({
      isOpen: "false",
    });
  };

  openLan = () => {
    $(".lang").toggleClass("show");
  };

  getLang = () => {
    axios({
      method: "GET",
      url: url + "/i18n/locales",
    })
      .then((res) => [
        this.setState({
          data: res.data,
          loading: false,
        }),
      ])
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  selectLang = (e) => {
    this.setState({
      lan: e,
    });
    if (localStorage) {
      localStorage.setItem("lan", e);
      window.location = window.location.href;
    } else {
    }
  };

  componentDidMount() {
    this.setState({
      pathname: window.location.pathname,
    });
    this.getLang();
    // this.initializeGoogleTagManager();
  }

  // initializeGoogleTagManager = () => {
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag() {
  //     window.dataLayer.push(arguments);
  //   }
  //   gtag('js', new Date());
  //   gtag('config', GTM_ID);
  // };

  openModal = () => {
    this.setState({
      show: "show",
    });
  };

  closeModal = () => {
    this.setState({
      show: "",
    });
  };

  handleNavLinkClick = (path) => {
    this.setState({
      pathname: path,
    });
  };

  isDropDownActive = (route) =>{
    switch(route){
      case "/webdevelopment/": return("/webdevelopment/")
      case "/mobiledevelopment/": return("/mobiledevelopment/")
      case "/smo/": return("/smo/")
      case "/seo/": return("/seo/")
      case "/japaneseclasses/": return("/japaneseclasses/")
      case "/courses/": return("/courses/")
    }
  }

  render() {
    const { isOpen, data, loading, pathname } = this.state;

    var lan_list = "";
    var lan_name = "";

    if (typeof window !== "undefined") {
      if (localStorage.getItem("lan") == null) {
        localStorage.setItem("lan", "en");
      } else if (localStorage.getItem("lan") != null) {
        this.langs = localStorage.getItem("lan");
      } else {
      }
      lan_list = lan[localStorage.getItem("lan")];
      lan_name = localStorage.getItem("lan");
    } else if (typeof window == "undefined") {
      lan_list = lan["en"];
      lan_name = "en";
    } else {
    }

    return (
      <>
        <nav className="navbar navbar-expand-lg fixed-top">
          <a className="navbar-brand" href="/">
            {" "}
            <img src={Logo.default} />{" "}
          </a>

          {isOpen == "false" ? (
            <>
              <button
                className="navbar-toggler"
                onClick={this.openNav}
                type="button"
                id="open_button"
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            </>
          ) : (
            ""
          )}

          {isOpen == "true" ? (
            <>
              <button
                className="navbar-toggler"
                onClick={this.closeNav}
                type="button"
                id="open_button"
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </>
          ) : (
            ""
          )}

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav">
              <li className={`nav-item ${pathname === "/" ? "active" : ""}`}>
                <Link className="nav-link" to={`/`}>
                  {lan_list[0]}
                </Link>
              </li>
              <li className={`nav-item ${pathname === "/about/" ? "active" : ""}`}>
                <Link className="nav-link" to={`/about/`}>
                  {lan_list[1]}
                </Link>
              </li>
              <li className={`nav-item ${pathname === "/team/" ? "active" : ""}`}>
                <Link className="nav-link" to={`/team/`}>
                  {lan_list[2]}
                </Link>
              </li>
              <li  className={`nav-item dropdown ${pathname === this.isDropDownActive(pathname)? "active" : ""}`}
 onClick={this.openDropDown}>
                <Link className={`nav-link ${pathname === this.isDropDownActive(pathname) ? "active" : ""}`}>
                  {lan_list[3]}{" "}
                  <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  <div className="dropdown-content">
                    <Link
                      className={`nav-link nav_main ${pathname === "/webdevelopment/" ? "active" : ""}`}
                      to={lan_list[7]["sub_menu_link"][0]}
                    >
                      {" "}
                      {lan_list[7]["sub_menu"][0]}{" "}
                    </Link>
                    <Link
                      className={`nav-link nav_main ${pathname === "/mobiledevelopment/" ? "active" : ""}`}
                      to={lan_list[7]["sub_menu_link"][1]}
                    >
                      {" "}
                      {lan_list[7]["sub_menu"][1]}{" "}
                    </Link>
                    <Link
                      className={`nav-link nav_main ${pathname === "/smo/" ? "active" : ""}`}
                      to={lan_list[7]["sub_menu_link"][2]}
                    >
                      {" "}
                      {lan_list[7]["sub_menu"][2]}{" "}
                    </Link>
                    <Link
                      className={`nav-link nav_main ${pathname === "/seo/" ? "active" : ""}`}
                      to={lan_list[7]["sub_menu_link"][3]}
                    >
                      {" "}
                      {lan_list[7]["sub_menu"][3]}{" "}
                    </Link>
                    <Link
                      className={`nav-link nav_main ${pathname === "/japaneseclasses/" ? "active" : ""}`}
                      to={lan_list[7]["sub_menu_link"][4]}
                    >
                      {" "}
                      {lan_list[7]["sub_menu"][4]}{" "}
                    </Link>

                    <Link
                      className={`nav-link nav_main ${pathname === "/germanclasses/" ? "active" : ""}`}
                      to={lan_list[7]["sub_menu_link"][5]}
                    >
                      {" "}
                      {lan_list[7]["sub_menu"][5]}{" "}
                    </Link>
                    <Link
                      className={`nav-link nav_main ${pathname === "/courses/" ? "active" : ""}`}
                      to={lan_list[7]["sub_menu_link"][6]}
                    >
                      {" "}
                      {lan_list[7]["sub_menu"][6]}{" "}
                    </Link>
                  </div>
                </Link>
              </li>
                    
              <li className={`nav-item ${pathname === "/howwedo/" ? "active" : ""}`} >
                <Link className="nav-link" to="/howwedo/">
                  {lan_list[4]}
                </Link>
              </li>
              <li className={`nav-item ${pathname === "/contactus/" ? "active" : ""}`}>
                <Link className="nav-link" to="/contactus/">
                  {lan_list[5]}
                </Link>
              </li>
              <li className={`nav-item ${pathname === "/blogs/" ? "active" : ""}`}>
                <Link className="nav-link" to="/blogs/">
                  {lan_list[6]}
                </Link>
              </li>
              <li className={`nav-item ${pathname === "/rateus/" ? "active" : ""}`}>
              <Link className="nav-link" to="/rateus/">
                  Rate Us
                </Link>
              </li>
              <li className="nav-item">
                <button className={"lan_icon"} onClick={this.openModal}>
                  {lan_name == "en" ? (
                    <>
                      <img src={EN.default} width={"40"} />
                    </>
                  ) : (
                    <></>
                  )}

                  {/*{lan_name == 'ja' ? (*/}
                  {/*    <><img src={JA.default} width={"30"} /></>*/}
                  {/*) : (*/}
                  {/*    <></>*/}
                  {/*)}*/}

                  {/*{lan_name == 'hi' ? (*/}
                  {/*    <><img src={IN.default} width={"30"} /></>*/}
                  {/*) : (*/}
                  {/*    <></>*/}
                  {/*)}*/}
                </button>
              </li>
             
            </ul>
          </div>
        </nav>

        <div
          className={"modal fade " + this.state.show}
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Language
                </h5>
                <button
                  type="button"
                  className="close btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className={"row"} align={"center"}>
                  <div className={"col-4"}>
                    <button
                      className={"trans_btn"}
                      value={"en"}
                      onClick={() => this.selectLang("en")}
                    >
                      <img src={EN.default} value={"en"} width={"80"} />
                      <br />
                      <span className={"language"}> English </span>
                    </button>
                  </div>
                  {/*<div className={"col-4"}>*/}
                  {/*    <button className={"trans_btn"} value={"ja"} onClick={() => this.selectLang('ja')}>*/}
                  {/*        <img src={JA.default} value={"ja"} />*/}
                  {/*        <br />*/}
                  {/*        <span className={"language"}> Japan </span>*/}
                  {/*    </button>*/}
                  {/*</div>*/}
                  {/*<div className={"col-4"}>*/}
                  {/*    <button className={"trans_btn"} value={"hi"} onClick={() => this.selectLang('hi')}>*/}
                  {/*        <img src={IN.default} value={"in"} />*/}
                  {/*        <br />*/}
                  {/*        <span className={"language"}> Hindi </span>*/}
                  {/*    </button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="mySidenav" className="sidenav">
          <ul className="navbar-nav">
            <li className="nav-item mb-3">
              <button className={"lan_icon"} onClick={this.openModal}>
                <img src={EN.default} width={"30"} />
              </button>
            </li>
            <li className="nav-item">
              <Link className="nav-link side_nav" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link side_nav" to="/about/">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link side_nav" to="/team/">
                Our Team
              </Link>
            </li>

            <li className="nav-item dropdown" onClick={this.openDropDown}>
              <Link className="nav-link side_nav">
                What We Do{" "}
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
                <div className="dropdown-content side_bar_content">
                  <Link
                    className="nav-link nav_main"
                    to={lan_list[7]["sub_menu_link"][0]}
                  >
                    {" "}
                    {lan_list[7]["sub_menu"][0]}{" "}
                  </Link>
                  <Link
                    className="nav-link nav_main"
                    to={lan_list[7]["sub_menu_link"][1]}
                  >
                    {" "}
                    {lan_list[7]["sub_menu"][1]}{" "}
                  </Link>
                  <Link
                    className="nav-link nav_main"
                    to={lan_list[7]["sub_menu_link"][2]}
                  >
                    {" "}
                    {lan_list[7]["sub_menu"][2]}{" "}
                  </Link>
                  <Link
                    className="nav-link nav_main"
                    to={lan_list[7]["sub_menu_link"][3]}
                  >
                    {" "}
                    {lan_list[7]["sub_menu"][3]}{" "}
                  </Link>
                  <Link
                    className="nav-link nav_main"
                    to={lan_list[7]["sub_menu_link"][4]}
                  >
                    {" "}
                    {lan_list[7]["sub_menu"][4]}{" "}
                  </Link>
                  <Link
                    className="nav-link nav_main"
                    to={lan_list[7]["sub_menu_link"][5]}
                  >
                    {" "}
                    {lan_list[7]["sub_menu"][5]}{" "}
                  </Link>
                </div>
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link side_nav" to="/howwedo/">
                How we do
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link side_nav" to="/contactus/">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link side_nav" to="/rateus/">
                  Rate Us
                </Link>
              </li>
            <li className="nav-item">
              <Link className="nav-link side_nav" to="/blogs/">
                Blogs
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default Navbar;